export const ApoCurrentStage4Side1 = [
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 8.26,
    rank: 1,
    avg_round: 3405,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 7.45,
    rank: 2,
    avg_round: 3459,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 3.95,
    rank: 3,
    avg_round: 3518,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 1.94,
    rank: 4,
    avg_round: 3281,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 1.67,
    rank: 5,
    avg_round: 3729,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 1.66,
    rank: 6,
    avg_round: 3426,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 1.63,
    rank: 7,
    avg_round: 3363,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 1.46,
    rank: 8,
    avg_round: 3397,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 1.28,
    rank: 9,
    avg_round: 3200,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 1.18,
    rank: 10,
    avg_round: 3444,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 1.09,
    rank: 11,
    avg_round: 3458,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 1.05,
    rank: 12,
    avg_round: 3426,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 1.01,
    rank: 13,
    avg_round: 3398,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.93,
    rank: 14,
    avg_round: 3729,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.93,
    rank: 14,
    avg_round: 3414,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.9,
    rank: 16,
    avg_round: 3435,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.87,
    rank: 17,
    avg_round: 3758,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.8,
    rank: 18,
    avg_round: 3659,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.78,
    rank: 19,
    avg_round: 3675,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.77,
    rank: 20,
    avg_round: 3095,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.72,
    rank: 21,
    avg_round: 3412,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.69,
    rank: 22,
    avg_round: 3657,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.61,
    rank: 23,
    avg_round: 3454,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.56,
    rank: 24,
    avg_round: 3419,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.56,
    rank: 24,
    avg_round: 3687,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.55,
    rank: 26,
    avg_round: 3198,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.55,
    rank: 26,
    avg_round: 3419,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.52,
    rank: 28,
    avg_round: 3440,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.5,
    rank: 29,
    avg_round: 3352,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.5,
    rank: 29,
    avg_round: 3521,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.49,
    rank: 31,
    avg_round: 3383,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.46,
    rank: 32,
    avg_round: 3211,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.46,
    rank: 32,
    avg_round: 3140,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.45,
    rank: 34,
    avg_round: 3418,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.45,
    rank: 34,
    avg_round: 3298,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.43,
    rank: 36,
    avg_round: 3318,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.43,
    rank: 36,
    avg_round: 3313,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.41,
    rank: 38,
    avg_round: 3424,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.41,
    rank: 38,
    avg_round: 3294,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.4,
    rank: 40,
    avg_round: 3392,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.38,
    rank: 41,
    avg_round: 3096,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.37,
    rank: 42,
    avg_round: 3455,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.36,
    rank: 43,
    avg_round: 3424,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.35,
    rank: 44,
    avg_round: 3111,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.35,
    rank: 44,
    avg_round: 3474,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.34,
    rank: 46,
    avg_round: 3075,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.33,
    rank: 47,
    avg_round: 3339,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.33,
    rank: 47,
    avg_round: 3426,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.32,
    rank: 49,
    avg_round: 3641,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.32,
    rank: 49,
    avg_round: 3356,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.32,
    rank: 49,
    avg_round: 3407,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.3,
    rank: 52,
    avg_round: 3668,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.3,
    rank: 52,
    avg_round: 3343,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.3,
    rank: 52,
    avg_round: 3407,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.28,
    rank: 55,
    avg_round: 3399,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.28,
    rank: 55,
    avg_round: 3165,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.28,
    rank: 55,
    avg_round: 3139,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'huohuo',
    app_rate: 0.28,
    rank: 55,
    avg_round: 3228,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.27,
    rank: 59,
    avg_round: 3357,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'aventurine',
    app_rate: 0.27,
    rank: 59,
    avg_round: 3216,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.27,
    rank: 59,
    avg_round: 3313,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.27,
    rank: 59,
    avg_round: 3420,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.27,
    rank: 59,
    avg_round: 3458,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.25,
    rank: 64,
    avg_round: 3176,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.24,
    rank: 65,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.24,
    rank: 65,
    avg_round: 3435,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'ruan-mei',
    app_rate: 0.24,
    rank: 65,
    avg_round: 3530,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.23,
    rank: 68,
    avg_round: 3194,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.23,
    rank: 68,
    avg_round: 3294,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.23,
    rank: 68,
    avg_round: 3409,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.23,
    rank: 68,
    avg_round: 3181,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.23,
    rank: 68,
    avg_round: 3354,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.23,
    rank: 68,
    avg_round: 3387,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.22,
    rank: 74,
    avg_round: 3493,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.21,
    rank: 75,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'sparkle',
    app_rate: 0.21,
    rank: 75,
    avg_round: 3584,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.21,
    rank: 75,
    avg_round: 3407,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.21,
    rank: 75,
    avg_round: 3304,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.21,
    rank: 75,
    avg_round: 3389,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.21,
    rank: 75,
    avg_round: 3287,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.2,
    rank: 81,
    avg_round: 3364,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'robin',
    app_rate: 0.2,
    rank: 81,
    avg_round: 3651,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'robin',
    app_rate: 0.2,
    rank: 81,
    avg_round: 3634,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.19,
    rank: 84,
    avg_round: 3217,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.19,
    rank: 84,
    avg_round: 3640,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.19,
    rank: 84,
    avg_round: 3152,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.19,
    rank: 84,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.19,
    rank: 84,
    avg_round: 3646,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.18,
    rank: 89,
    avg_round: 3367,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.18,
    rank: 89,
    avg_round: 3626,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.18,
    rank: 89,
    avg_round: 3322,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.17,
    rank: 92,
    avg_round: 3409,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.17,
    rank: 92,
    avg_round: 3385,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.17,
    rank: 92,
    avg_round: 3293,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 95,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 95,
    avg_round: 3453,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.16,
    rank: 95,
    avg_round: 3287,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.16,
    rank: 95,
    avg_round: 3572,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.16,
    rank: 95,
    avg_round: 3341,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.16,
    rank: 95,
    avg_round: 3373,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.16,
    rank: 95,
    avg_round: 3279,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.15,
    rank: 102,
    avg_round: 3582,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.15,
    rank: 102,
    avg_round: 3345,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.15,
    rank: 102,
    avg_round: 3574,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gallagher',
    app_rate: 0.14,
    rank: 105,
    avg_round: 3176,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 105,
    avg_round: 3535,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.14,
    rank: 105,
    avg_round: 3384,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.14,
    rank: 105,
    avg_round: 3359,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.14,
    rank: 105,
    avg_round: 3411,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.14,
    rank: 105,
    avg_round: 3225,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 105,
    avg_round: 3383,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 105,
    avg_round: 3386,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.14,
    rank: 105,
    avg_round: 3110,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.13,
    rank: 114,
    avg_round: 3623,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.13,
    rank: 114,
    avg_round: 3455,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.13,
    rank: 114,
    avg_round: 3700,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 114,
    avg_round: 3367,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.13,
    rank: 114,
    avg_round: 3670,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.13,
    rank: 114,
    avg_round: 3700,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 114,
    avg_round: 3218,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.12,
    rank: 121,
    avg_round: 3443,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 121,
    avg_round: 3161,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.12,
    rank: 121,
    avg_round: 3370,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 121,
    avg_round: 3336,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 121,
    avg_round: 3426,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.12,
    rank: 121,
    avg_round: 3668,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 121,
    avg_round: 3274,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 121,
    avg_round: 3380,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.12,
    rank: 121,
    avg_round: 3262,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.12,
    rank: 121,
    avg_round: 3340,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3356,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3368,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3634,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3618,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3236,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3412,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.11,
    rank: 131,
    avg_round: 3251,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3335,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3153,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3460,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3160,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3309,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 138,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3502,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3144,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3381,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3185,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3328,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3348,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3396,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3407,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3393,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3405,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3331,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3288,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.1,
    rank: 138,
    avg_round: 3186,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3310,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3371,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3404,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3255,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3220,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.09,
    rank: 157,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3541,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3512,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3393,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3382,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3583,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3287,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3573,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3228,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'luocha',
    app_rate: 0.09,
    rank: 157,
    avg_round: 3236,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3404,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3365,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3244,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.08,
    rank: 172,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 172,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3418,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3362,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3391,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3370,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3283,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3089,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3703,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3393,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3356,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3350,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3295,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3572,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3370,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3383,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3349,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'aglaea',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3728,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3348,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3382,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3659,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.08,
    rank: 172,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3591,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3089,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3314,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3457,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3623,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 172,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.08,
    rank: 172,
    avg_round: 3292,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3504,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3298,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3271,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.07,
    rank: 204,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3308,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3434,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3273,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3447,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3172,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3305,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.07,
    rank: 204,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3442,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3701,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3644,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.07,
    rank: 204,
    avg_round: 3490,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 219,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3219,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3644,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3129,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3293,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3262,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3626,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3240,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3017,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3224,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3381,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3369,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3628,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3326,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3382,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3608,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 219,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3380,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3209,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3286,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3336,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'gallagher',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3282,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.06,
    rank: 219,
    avg_round: 3071,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3397,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3170,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3473,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3560,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3139,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'tingyun',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3654,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3519,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3338,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3450,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3634,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3429,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 242,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3694,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3366,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3517,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3528,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'topaz',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3591,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3382,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3396,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3274,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3540,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'bailu',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3096,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3612,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3205,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3304,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3034,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3384,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3304,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3215,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'moze',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3586,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3170,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3247,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'gepard',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3339,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3287,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3507,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3314,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3217,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3326,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3295,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3379,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3342,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3161,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3082,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3549,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3428,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3629,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3506,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3328,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3513,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3697,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3428,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3520,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3366,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3196,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3402,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.05,
    rank: 242,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.05,
    rank: 242,
    avg_round: 3570,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3080,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3355,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3371,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3291,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3396,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3370,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3184,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3548,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3193,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3674,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3378,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.04,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3329,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3135,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3312,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3505,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3209,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3319,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3513,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.04,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3382,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3536,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3414,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3588,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3134,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3368,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'moze',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3219,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3398,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3512,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3551,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.04,
    rank: 303,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3267,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.04,
    rank: 303,
    avg_round: 3311,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3294,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3690,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3338,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3368,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3402,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3276,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3460,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3638,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3488,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3094,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3285,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3410,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3511,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'silver-wolf',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3215,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3225,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3546,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3375,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3396,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3446,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3124,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3345,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3444,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3461,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3351,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3407,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'pela',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3443,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'trailblazer-remembrance',
    char_three: 'silver-wolf',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3635,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3348,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3563,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3630,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3424,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3291,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3290,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3282,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 340,
    avg_round: 2996,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3179,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3534,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3383,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3081,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'silver-wolf',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3497,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3339,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3306,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3507,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3027,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3151,
    star_num: '4'
  },
  {
    char_one: 'bronya',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3714,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3372,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3222,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3249,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3296,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'pela',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3564,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3358,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3309,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3371,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3530,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3553,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3232,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3505,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3648,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3117,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3444,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3254,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3314,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3551,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3115,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3472,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3258,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'silver-wolf',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3190,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3213,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3223,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3124,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3298,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3698,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3549,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3401,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3240,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3319,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3384,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3630,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3557,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3234,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3400,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3624,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3248,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'jiaoqiu',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3176,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3510,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gepard',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3518,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3342,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3446,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3214,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3598,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3375,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3003,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'feixiao',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3780,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'march-7th-swordmaster',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3526,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3407,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3411,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3466,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3540,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3262,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3576,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3549,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3471,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3331,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3360,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3379,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3578,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3428,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.03,
    rank: 340,
    avg_round: 3319,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.03,
    rank: 340,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3613,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3285,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3350,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3178,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3429,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3420,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3420,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3480,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3156,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3557,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3631,
    star_num: '4'
  },
  {
    char_one: 'lingsha',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3267,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3528,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3501,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3358,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3462,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3322,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3293,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3263,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3442,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'luka',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3570,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3358,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3165,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3112,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3323,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3411,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3578,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3423,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3378,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3671,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3763,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3488,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3359,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3078,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'pela',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3586,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3430,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3355,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'feixiao',
    char_three: 'herta',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3392,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3187,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3295,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3584,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3378,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'sushang',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3481,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3425,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3538,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3050,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3253,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3380,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3506,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3375,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'boothill',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3567,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3362,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 2880,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3325,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3646,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3196,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3169,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3269,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'sushang',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3332,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 2987,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3346,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'gepard',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3225,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3190,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'tingyun',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3552,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3363,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3448,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3548,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3526,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'feixiao',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3696,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3449,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3290,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3326,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'moze',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3192,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3450,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3026,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3244,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3407,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3017,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3174,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3622,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3341,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'pela',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3527,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'aglaea',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3304,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3473,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3445,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3530,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3282,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3338,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3403,
    star_num: '4'
  },
  {
    char_one: 'sushang',
    char_two: 'tingyun-fugue',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3332,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3468,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3421,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3589,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.02,
    rank: 482,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3490,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.02,
    rank: 482,
    avg_round: 3165,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3066,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3492,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3334,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3091,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3336,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3293,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3399,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3267,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'aglaea',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3332,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3583,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'trailblazer-remembrance',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3452,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3183,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3279,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3555,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3278,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3469,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3322,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3141,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3673,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3321,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3121,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'aglaea',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 2919,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'trailblazer-remembrance',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'bronya',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3574,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'topaz',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'dan-heng',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3620,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3455,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'acheron',
    char_three: 'tingyun-fugue',
    char_four: 'jiaoqiu',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3429,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'robin',
    char_four: 'march-7th',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'silver-wolf',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'robin',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3333,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3175,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3562,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3511,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3217,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3415,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3533,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3433,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3557,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3251,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3416,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3535,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'sampo',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3441,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3496,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'moze',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3277,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3370,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3442,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3307,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3446,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'clara',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3122,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'trailblazer-remembrance',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3275,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3196,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'trailblazer-remembrance',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3426,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3282,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3691,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3263,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'bronya',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3438,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3387,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'lynx',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3490,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3374,
    star_num: '4'
  },
  {
    char_one: 'yanqing',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 2991,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'boothill',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'rappa',
    char_two: 'firefly',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3328,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'feixiao',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3392,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'sparkle',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3260,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3700,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3711,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'march-7th',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3194,
    star_num: '4'
  },
  {
    char_one: 'yanqing',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3281,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3368,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yanqing',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3572,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3140,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'tingyun-fugue',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3471,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3446,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3209,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'pela',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3535,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3359,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'trailblazer-remembrance',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3449,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'topaz',
    char_three: 'tribbie',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3451,
    star_num: '4'
  },
  {
    char_one: 'clara',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3182,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 2964,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'trailblazer-remembrance',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 616,
    avg_round: 2946,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'moze',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3145,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'tingyun',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'herta',
    char_three: 'tingyun-fugue',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3648,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3393,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3086,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3421,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'trailblazer-remembrance',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3556,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'pela',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3432,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'aglaea',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3378,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3252,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3356,
    star_num: '4'
  },
  {
    char_one: 'sushang',
    char_two: 'tingyun-fugue',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3551,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3294,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3523,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'robin',
    char_three: 'silver-wolf',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3404,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3291,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3590,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'tingyun-fugue',
    char_four: 'pela',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3563,
    star_num: '4'
  },
  {
    char_one: 'kafka',
    char_two: 'black-swan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'natasha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'herta',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3381,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3397,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'silver-wolf',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'sunday',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3617,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3259,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'pela',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3345,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'tingyun',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sparkle',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3033,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3444,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3452,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'bronya',
    char_three: 'robin',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3244,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'qingque',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3251,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'robin',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3247,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'pela',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'silver-wolf',
    char_three: 'tingyun',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3590,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'jiaoqiu',
    char_three: 'pela',
    char_four: 'silver-wolf',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3403,
    star_num: '4'
  },
  {
    char_one: 'yanqing',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'sparkle',
    char_three: 'tribbie',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'natasha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3519,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jing-yuan',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3284,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'bronya',
    char_three: 'jiaoqiu',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'sunday',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3121,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'trailblazer-harmony',
    char_three: 'sunday',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3522,
    star_num: '4'
  },
  {
    char_one: 'blade',
    char_two: 'ruan-mei',
    char_three: 'sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'trailblazer-remembrance',
    char_three: 'pela',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3282,
    star_num: '4'
  },
  {
    char_one: 'jade',
    char_two: 'feixiao',
    char_three: 'bronya',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'boothill',
    char_two: 'bronya',
    char_three: 'ruan-mei',
    char_four: 'gepard',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3476,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'sparkle',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3562,
    star_num: '4'
  },
  {
    char_one: 'aglaea',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3344,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-remembrance',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'pela',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3196,
    star_num: '4'
  },
  {
    char_one: 'argenti',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'blade',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3331,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'tingyun-fugue',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3292,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'sparkle',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'robin',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3729,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'trailblazer-remembrance',
    char_three: 'tribbie',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3562,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'firefly',
    char_two: 'feixiao',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'sparkle',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'moze',
    char_three: 'robin',
    char_four: 'aventurine',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3140,
    star_num: '4'
  },
  {
    char_one: 'seele',
    char_two: 'trailblazer-remembrance',
    char_three: 'silver-wolf',
    char_four: 'tingyun',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3605,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'bronya',
    char_four: 'huohuo',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3424,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'herta',
    char_three: 'robin',
    char_four: 'bailu',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3341,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'robin',
    char_three: 'sparkle',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3535,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'robin',
    char_three: 'sunday',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'herta',
    char_two: 'march-7th-swordmaster',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'kafka',
    char_three: 'black-swan',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'jade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'ruan-mei',
    char_three: 'sunday',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3410,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'sunday',
    char_three: 'tribbie',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3056,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'blade',
    char_three: 'bronya',
    char_four: 'ruan-mei',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3252,
    star_num: '4'
  },
  {
    char_one: 'jingliu',
    char_two: 'trailblazer-remembrance',
    char_three: 'ruan-mei',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3201,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'pela',
    char_three: 'sparkle',
    char_four: 'luocha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'sunday',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'argenti',
    char_three: 'robin',
    char_four: 'tribbie',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yunli',
    char_two: 'sunday',
    char_three: 'tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3272,
    star_num: '4'
  },
  {
    char_one: 'acheron',
    char_two: 'black-swan',
    char_three: 'jiaoqiu',
    char_four: 'gallagher',
    app_rate: 0.01,
    rank: 616,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'feixiao',
    char_two: 'yunli',
    char_three: 'trailblazer-remembrance',
    char_four: 'robin',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3540,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-remembrance',
    char_three: 'sunday',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3500,
    star_num: '4'
  },
  {
    char_one: 'the-herta',
    char_two: 'serval',
    char_three: 'robin',
    char_four: 'lingsha',
    app_rate: 0.01,
    rank: 616,
    avg_round: 3396,
    star_num: '4'
  }
];
