export const HSRApoHisto = [
  {
    slug: 'acheron',
    score: 3357,
    usage: 37.15,
    role: 'dps',
    new: false
  },
  {
    slug: 'aglaea',
    score: 3468,
    usage: 9.45,
    role: 'dps',
    new: false
  },
  {
    slug: 'argenti',
    score: 3411,
    usage: 3.22,
    role: 'dps',
    new: false
  },
  {
    slug: 'arlan',
    score: 3000,
    usage: 0.01,
    role: 'dps',
    new: false
  },
  {
    slug: 'asta',
    score: 3347,
    usage: 0.29,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'aventurine',
    score: 3431,
    usage: 54.42,
    role: 'sustain',
    new: false
  },
  {
    slug: 'bailu',
    score: 3341,
    usage: 0.73,
    role: 'sustain',
    new: false
  },
  {
    slug: 'black-swan',
    score: 3269,
    usage: 10.39,
    role: 'specialist',
    new: false
  },
  {
    slug: 'blade',
    score: 3274,
    usage: 0.99,
    role: 'dps',
    new: false
  },
  {
    slug: 'boothill',
    score: 3387,
    usage: 3.58,
    role: 'dps',
    new: false
  },
  {
    slug: 'bronya',
    score: 3433,
    usage: 6.99,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'clara',
    score: 3112,
    usage: 0.79,
    role: 'dps',
    new: false
  },
  {
    slug: 'dan-heng',
    score: 3000,
    usage: 0.03,
    role: 'dps',
    new: false
  },
  {
    slug: 'dr-ratio',
    score: 3114,
    usage: 0.43,
    role: 'dps',
    new: false
  },
  {
    slug: 'feixiao',
    score: 3463,
    usage: 25.96,
    role: 'dps',
    new: false
  },
  {
    slug: 'firefly',
    score: 3379,
    usage: 39.83,
    role: 'dps',
    new: false
  },
  {
    slug: 'fu-xuan',
    score: 3379,
    usage: 20.78,
    role: 'sustain',
    new: false
  },
  {
    slug: 'gallagher',
    score: 3421,
    usage: 39.67,
    role: 'sustain',
    new: false
  },
  {
    slug: 'gepard',
    score: 3342,
    usage: 8.7,
    role: 'sustain',
    new: false
  },
  {
    slug: 'guinaifen',
    score: 3286,
    usage: 1.13,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'hanya',
    score: 3129,
    usage: 0.15,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'herta',
    score: 3371,
    usage: 12.68,
    role: 'specialist',
    new: false
  },
  {
    slug: 'himeko',
    score: 3333,
    usage: 10.3,
    role: 'dps',
    new: false
  },
  {
    slug: 'hook',
    score: 3000,
    usage: 0.41,
    role: 'dps',
    new: false
  },
  {
    slug: 'huohuo',
    score: 3400,
    usage: 12.12,
    role: 'sustain',
    new: false
  },
  {
    slug: 'imbibitor-lunae',
    score: 3159,
    usage: 11.04,
    role: 'dps',
    new: false
  },
  {
    slug: 'jade',
    score: 3485,
    usage: 10.06,
    role: 'specialist',
    new: false
  },
  {
    slug: 'jiaoqiu',
    score: 3406,
    usage: 22.48,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'jing-yuan',
    score: 3358,
    usage: 13.59,
    role: 'dps',
    new: false
  },
  {
    slug: 'jingliu',
    score: 3215,
    usage: 4.11,
    role: 'dps',
    new: false
  },
  {
    slug: 'kafka',
    score: 3275,
    usage: 9.21,
    role: 'dps',
    new: false
  },
  {
    slug: 'lingsha',
    score: 3496,
    usage: 27.22,
    role: 'sustain',
    new: false
  },
  {
    slug: 'luka',
    score: 3000,
    usage: 13.4,
    role: 'dps',
    new: false
  },
  {
    slug: 'luocha',
    score: 3381,
    usage: 6.0,
    role: 'sustain',
    new: false
  },
  {
    slug: 'lynx',
    score: 3321,
    usage: 3.67,
    role: 'sustain',
    new: false
  },
  {
    slug: 'march-7th',
    score: 3000,
    usage: 0.17,
    role: 'sustain',
    new: false
  },
  {
    slug: 'march-7th-swordmaster',
    score: 3421,
    usage: 6.26,
    role: 'specialist',
    new: false
  },
  {
    slug: 'misha',
    score: 3000,
    usage: 6.54,
    role: 'dps',
    new: false
  },
  {
    slug: 'moze',
    score: 3400,
    usage: 0.91,
    role: 'specialist',
    new: false
  },
  {
    slug: 'mydei',
    score: 3400,
    usage: 2.75,
    role: 'dps',
    new: true
  },
  {
    slug: 'natasha',
    score: 3000,
    usage: 0.53,
    role: 'sustain',
    new: false
  },
  {
    slug: 'pela',
    score: 3379,
    usage: 14.14,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'qingque',
    score: 3090,
    usage: 5.0,
    role: 'dps',
    new: false
  },
  {
    slug: 'rappa',
    score: 3427,
    usage: 9.97,
    role: 'dps',
    new: false
  },
  {
    slug: 'robin',
    score: 3434,
    usage: 35.79,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'ruan-mei',
    score: 3431,
    usage: 72.24,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'sampo',
    score: 3074,
    usage: 13.32,
    role: 'specialist',
    new: false
  },
  {
    slug: 'seele',
    score: 3263,
    usage: 0.79,
    role: 'dps',
    new: false
  },
  {
    slug: 'serval',
    score: 3421,
    usage: 2.06,
    role: 'specialist',
    new: false
  },
  {
    slug: 'silver-wolf',
    score: 3316,
    usage: 9.0,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'sparkle',
    score: 3322,
    usage: 13.92,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'sunday',
    score: 3417,
    usage: 27.44,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'sushang',
    score: 3145,
    usage: 13.98,
    role: 'dps',
    new: false
  },
  {
    slug: 'the-herta',
    score: 3508,
    usage: 33.46,
    role: 'dps',
    new: false
  },
  {
    slug: 'tingyun',
    score: 3293,
    usage: 23.66,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'tingyun-fugue',
    score: 3409,
    usage: 21.45,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'topaz',
    score: 3444,
    usage: 10.83,
    role: 'specialist',
    new: false
  },
  {
    slug: 'trailblazer-destruction',
    score: 3000,
    usage: 0.01,
    role: 'dps',
    new: false
  },
  {
    slug: 'trailblazer-harmony',
    score: 3402,
    usage: 38.45,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'trailblazer-preservation',
    score: 3000,
    usage: 0.0,
    role: 'sustain',
    new: false
  },
  {
    slug: 'trailblazer-remembrance',
    score: 3518,
    usage: 33.46,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'tribbie',
    score: 3548,
    usage: 44.04,
    role: 'specialist',
    new: true
  },
  {
    slug: 'welt',
    score: 3179,
    usage: 0.72,
    role: 'specialist',
    new: false
  },
  {
    slug: 'xueyi',
    score: 3069,
    usage: 0.22,
    role: 'dps',
    new: false
  },
  {
    slug: 'yanqing',
    score: 3000,
    usage: 0.05,
    role: 'dps',
    new: false
  },
  {
    slug: 'yukong',
    score: 3000,
    usage: 0.01,
    role: 'amplifier',
    new: false
  },
  {
    slug: 'yunli',
    score: 3239,
    usage: 3.25,
    role: 'dps',
    new: false
  }
];
