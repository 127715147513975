/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import './memory-of-chaos.scss';
import {
  Row,
  Col,
  Button,
  Card,
  Popover,
  Alert,
  Collapse,
  FloatingLabel,
  Form,
  OverlayTrigger
} from 'react-bootstrap';
import { Box } from '../../modules/common/components/box';
import { ApoCharacterData } from '../../modules/hsr/apo/char-stats';
import { ApoSupportedCharactersList as listCharacters } from '../../modules/hsr/apo/supported-chars';
import { ApoCurrentAll } from '../../modules/hsr/apo/current-all';
import { ApoCurrentStage4Side1 } from '../../modules/hsr/apo/current-4-1';
import { ApoCurrentStage4Side2 } from '../../modules/hsr/apo/current-4-2';
import { HSRCharacter } from '../../modules/hsr/common/components/hsr-character';
import {
  faArrowUp,
  faArrowDown,
  faFilter,
  faCircleQuestion,
  faBriefcaseMedical,
  faFlask,
  faHandFist,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from 'react-switch';
import { HSRApoHisto } from '../../modules/hsr/apo/histo';

// enum to replace strings. Not necessary, but good practice.
enum CharacterState {
  include,
  exclude
}

enum FilterMode {
  includeAny,
  includeAll
}

// interface for grouping together character use state information
interface ICharacterUseStateInfo {
  name: string;
  state: CharacterState;
  setFunction: (CharacterState: CharacterState) => void;
}

const HSRApoAnalyticsPage: React.FC = () => {
  const [characterList, setCharacterList] = useState(ApoCharacterData);
  const [characterListCombined, setCharacterListCombined] = useState(false);
  const [enableCycle, setEnableCycle] = useState(false);
  const [characterListEidolon, setCharacterListEidolon] = useState(false);
  const [currentTeamStageConfig, setCurrentTeamStageConfig] = useState('all');
  const [currentRatingsConfig, setCurrentRatingsConfig] = useState('default');
  const [currentTeamStage, setCurrentTeamStage] = useState(ApoCurrentAll);
  const [currentTeamStage2, setCurrentTeamStage2] = useState(ApoCurrentAll);
  const [currentTeamStageFiltered, setCurrentTeamStageFiltered] =
    useState(ApoCurrentAll);
  const [currentTeamStage2Filtered, setCurrentTeamStage2Filtered] =
    useState(ApoCurrentAll);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [currentPhase, setCurrentPhase] = useState('3.1');
  const [currentTotalUsers, setCurrentTotalUsers] = useState('18809');
  const [currentSelfUsers, setCurrentSelfUsers] = useState('10005');
  const [currentRandomUsers, setCurrentRandomUsers] = useState('8804');
  const [updateDate, setUpdateDate] = useState('24/03/2025');

  // filter mode
  const [currentFilterMode, setFilterMode] = useState(FilterMode.includeAny);

  // create all supported character icon states
  const listCharacterUseStates: Array<ICharacterUseStateInfo> = [];
  // just the states in a list
  const listCharacterStates: Array<CharacterState> = [];
  listCharacters.map((entry) => {
    const [filterCharacterMode, setFilterCharacterMode] = useState(
      CharacterState.include
    );
    listCharacterUseStates.push({
      name: entry['value'],
      state: filterCharacterMode,
      setFunction: setFilterCharacterMode
    });
    listCharacterStates.push(filterCharacterMode);
  });

  const [open, setOpen] = useState(true);

  const [currentShow, setCurrentShow] = useState(10);

  const handleShowMore = () => {
    setCurrentShow(50);
  };

  const handleShowLess = () => {
    setCurrentShow(10);
  };

  useEffect(() => {
    if (currentTeamStageConfig === 'all') {
      setCurrentTeamStage(ApoCurrentAll);
      setCurrentTeamStage2(ApoCurrentAll);
      setCurrentTeamStageFiltered(ApoCurrentAll);
      setCurrentTeamStage2Filtered(ApoCurrentAll);
    } else if (currentTeamStageConfig === 'stage_4') {
      setCurrentTeamStage(ApoCurrentStage4Side1);
      setCurrentTeamStage2(ApoCurrentStage4Side2);
      setCurrentTeamStageFiltered(ApoCurrentStage4Side1);
      setCurrentTeamStage2Filtered(ApoCurrentStage4Side2);
    }

    setEnableCycle(false);
    // set all filters on all characters to 'include'
    const currentDefaultCharState: CharacterState = CharacterState.include;
    listCharacterUseStates.map((entry) => {
      entry.setFunction(currentDefaultCharState);
    });
  }, [currentTeamStageConfig]);

  useEffect(() => {
    if (enableCycle === true) {
      currentTeamStageFiltered.sort((a, b) =>
        b.avg_round > a.avg_round ? 1 : -1
      );
      currentTeamStage2Filtered.sort((a, b) =>
        b.avg_round > a.avg_round ? 1 : -1
      );
    } else {
      currentTeamStageFiltered.sort((a, b) =>
        b.app_rate > a.app_rate ? 1 : -1
      );
      currentTeamStage2Filtered.sort((a, b) =>
        b.app_rate > a.app_rate ? 1 : -1
      );
    }
    forceUpdate();
  }, [enableCycle]);

  useEffect(() => {
    const filtered = currentTeamStage;
    const filtered2 = currentTeamStage2;
    const listFiltered = [filtered, filtered2];

    // filter function. Filters both stages for duplicate code reduction
    // the lists within listFiltered will be updated. Function has void return type.
    // note: functionFilter should take and return an object of the same type as the elements of listFiltered
    const functionFilterAll = (listFiltered, functionFilter) => {
      for (let i = 0; i < listFiltered.length; i++) {
        listFiltered[i] = functionFilter(listFiltered[i]);
      }
    };

    // filter mode based on toggle
    // block to restrict dictFilter scope. Unnecessary.
    {
      const dictFilter: { [name: string]: ICharacterUseStateInfo } = {};
      let dictLength = 0;
      switch (currentFilterMode) {
        case FilterMode.includeAny:
          // teams can be made of of any combination of selected units. Filter based on the existence of unselected units.
          // create filter dict of unselected units for faster access
          listCharacterUseStates.map((entry) => {
            if (entry.state === CharacterState.exclude) {
              dictFilter[entry.name] = entry;
            }
          });

          functionFilterAll(listFiltered, (filtered) => {
            return filtered.filter(
              (emp) =>
                // logical not in front of what is expected to be undefined (falsey value)
                !dictFilter[emp.char_one.toLowerCase()] &&
                !dictFilter[emp.char_two.toLowerCase()] &&
                !dictFilter[emp.char_three.toLowerCase()] &&
                !dictFilter[emp.char_four.toLowerCase()]
            );
          });
          break;
        case FilterMode.includeAll:
          // teams must have all selected units. Filter based on the existence of selected units.
          // create filter dict of selected units for faster access. Track filter length.
          listCharacterUseStates.map((entry) => {
            if (entry.state === CharacterState.include) {
              dictFilter[entry.name] = entry;
              dictLength++;
            }
          });

          // When the selection is empty, set lists to empty
          if (dictLength == 0) {
            listFiltered[0] = [];
            listFiltered[1] = [];
          } else {
            functionFilterAll(listFiltered, (filtered) => {
              return filtered.filter((emp) => {
                // filter based on how many remaining wild cards there are
                let selectedCount = 0;
                if (dictFilter[emp.char_one.toLowerCase()]) {
                  selectedCount++;
                }
                if (dictFilter[emp.char_two.toLowerCase()]) {
                  selectedCount++;
                }
                if (dictFilter[emp.char_three.toLowerCase()]) {
                  selectedCount++;
                }
                if (dictFilter[emp.char_four.toLowerCase()]) {
                  selectedCount++;
                }

                // if the two numbers match, then all selected chars are in the team.
                // selectedCount should never be greater than dictLength, but it probably should behave this way if it did.
                if (selectedCount >= dictLength) {
                  return true;
                }
                return false;
              });
            });
          }

          break;

        default:
          // should never be reached
          break;
      }
    }

    setEnableCycle(false);
    setCurrentTeamStageFiltered(listFiltered[0]);
    setCurrentTeamStage2Filtered(listFiltered[1]);
  }, [...listCharacterStates, currentFilterMode]);

  const resetStuff: () => void = () => {
    listCharacterUseStates.map((entry) =>
      entry.setFunction(CharacterState.include)
    );
  };

  // deselect everything
  const unsetStuff: () => void = () => {
    listCharacterUseStates.map((entry) =>
      entry.setFunction(CharacterState.exclude)
    );
  };

  // toggle filter mode.
  // any -> all deselects everything
  // all -> any does not change selection
  const toggleFilterMode: () => void = () => {
    switch (currentFilterMode) {
      case FilterMode.includeAny:
        listCharacterUseStates.map((entry) =>
          entry.setFunction(CharacterState.exclude)
        );
        setFilterMode(FilterMode.includeAll);
        break;
      case FilterMode.includeAll:
        setFilterMode(FilterMode.includeAny);
        break;
      default:
        // unreachable in theory
        break;
    }
  };

  const items = currentTeamStageFiltered
    .slice(0, currentShow)
    .map((item, index) => (
      <div className="team-row" key={index}>
        <div className="column info">
          <p className="rank">Rank {item.rank}</p>
          <p className="usage">App. rate: {item.app_rate}%</p>
          {currentTeamStageConfig === 'all' ||
          currentTeamStageConfig === 'stage_4' ? (
            <>
              <p className="rounds">Avg. score: {item.avg_round}</p>
            </>
          ) : (
            <>
              <p className="rounds">Avg. score: {item.avg_round.toFixed(0)}</p>
            </>
          )}
        </div>
        <div className="column characters">
          <HSRCharacter
            slug={item.char_one.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_two.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_three.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_four.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
        </div>
      </div>
    ));

  const items2 = currentTeamStage2Filtered
    .slice(0, currentShow)
    .map((item, index) => (
      <div className="team-row" key={index}>
        <div className="column info">
          <p className="rank">Rank {item.rank}</p>
          <p className="usage">App. rate: {item.app_rate}%</p>
          {currentTeamStageConfig === 'all' ||
          currentTeamStageConfig === 'stage_4' ? (
            <>
              <p className="rounds">Avg. score: {item.avg_round}</p>
            </>
          ) : (
            <>
              <p className="rounds">Avg. score: {item.avg_round.toFixed(0)}</p>
            </>
          )}
        </div>
        <div className="column characters">
          <HSRCharacter
            slug={item.char_one.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_two.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_three.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
          <HSRCharacter
            slug={item.char_four.toLowerCase()}
            mode="icon"
            showIcon
            enablePopover
          />
        </div>
      </div>
    ));

  useEffect(() => {
    setCurrentTeamStage(ApoCurrentAll);
    setCurrentTeamStage2(ApoCurrentAll);
  }, []);

  // creates include/exclude character icons for filtering
  const createFilterCharacterIcons: React.FC = () => {
    // only calculate selected count if in includeAll filter mode
    let currentSelectedCount = 0;
    if (currentFilterMode === FilterMode.includeAll) {
      listCharacterUseStates.map((entry) => {
        if (entry.state === CharacterState.include) {
          currentSelectedCount++;
        }
      });
    }

    return (
      <div className={`employees-container`}>
        {listCharacterUseStates.map((entry, index) => {
          const isSelected = entry.state === CharacterState.include;
          return (
            <span key={index}>
              {isSelected ||
              !(
                currentFilterMode === FilterMode.includeAll &&
                currentSelectedCount >= 4
              ) ? (
                <Card
                  onClick={() => {
                    switch (entry.state) {
                      case CharacterState.include:
                        entry.setFunction(CharacterState.exclude);
                        break;
                      case CharacterState.exclude:
                        entry.setFunction(CharacterState.include);
                        break;
                      default:
                        break;
                    }
                  }}
                  className={`avatar-card ${isSelected ? 'selected' : ''}`}
                >
                  <HSRCharacter
                    slug={entry.name}
                    mode="icon-no-link"
                    showIcon
                  />
                </Card>
              ) : (
                <Card className="avatar-card">
                  <HSRCharacter
                    slug={entry.name}
                    mode="icon-no-link"
                    showIcon
                  />
                </Card>
              )}
            </span>
          );
        })}
      </div>
    );
  };

  const popoverStrict = (
    <Popover id="popover-strict">
      <Popover.Header as="h3">Strict Mode</Popover.Header>
      <Popover.Body>
        <strong>Strict Mode</strong> will show teams that contain only the
        characters you have selected (limit up to 4 characters).
      </Popover.Body>
    </Popover>
  );

  return (
    <DashboardLayout className={'generic-page memory-of-chaos'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Apocalyptic Shadow Analytics ({currentPhase})</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_apo.jpg"
            alt="MoC"
          />
        </div>
        <div className="page-details">
          <h1>Apocalyptic Shadow Analytics ({currentPhase})</h1>
          <h2>
            Information and analytics about the current and past Apocalyptic
            Shadow phases!
          </h2>
          <p>
            Last updated: <strong>{updateDate}</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Foreword" />
      <p>
        The Apocalyptic Shadow Analytics is the fruit of collaboration between
        Prydwen and{' '}
        <strong>
          Spiral Stats who create beautiful infographics with character and team
          usage for Genshin Impact
        </strong>
        . They recently branched out to Honkai: Star Rail, so expect similar
        great images to be prepared by them for each Apocalyptic Shadow phase!
      </p>
      <p>You can check their Honkai: Star Rail works by going here:</p>
      <Button
        variant="primary"
        href="https://www.reddit.com/user/LvlUrArti/comments/155o3wi/compilation_of_my_honkai_star_rail_infographics/"
        target="_blank"
        rel="noreferrer"
        className="custom-button"
      >
        Spiral Stats
      </Button>
      <p>
        I would like to thank{' '}
        <strong>
          Grimro,{' '}
          <a
            href="https://discord.gg/pokkesvillage"
            target="_blank"
            rel="noreferrer"
          >
            MrPokke
          </a>
          ,{' '}
          <a
            href="https://discord.gg/KQMStarRail"
            target="_blank"
            rel="noreferrer"
          >
            KQM
          </a>
          ,{' '}
          <a
            href="https://discord.gg/kafkamains"
            target="_blank"
            rel="noreferrer"
          >
            Kafka Mains
          </a>{' '}
          and{' '}
          <a
            href="http://discord.gg/guobafans"
            target="_blank"
            rel="noreferrer"
          >
            Guoba
          </a>{' '}
          communities for sharing their clear information with us!
        </strong>{' '}
        The stats you can find on the page will be updated on a bi-weekly basis
        to make them as accurate as possible and help you with clearing the
        current Apocalyptic Shadow phase!
      </p>
      <p>
        If you have any suggestions about the contents of this page, contact the
        Spiral Stats team on Discord (username: lvlurarti) or Reddit (username:
        LvlUrArti). Also, if you want to see the raw data we have gathered, you
        can find them on our GitHub account,{' '}
        <a
          href="https://github.com/piedorr/MocStats"
          target="_blank"
          rel="noreferrer"
        >
          here.
        </a>{' '}
      </p>
      <SectionHeader title="Help us!" />
      <div className="top-partner-intro hsr">
        <Box>
          <Row>
            <Col>
              <div className="inner">
                <h2>Help us make the Analytics better!</h2>
                <p>
                  You just need to share your UID to help us. It's so simple!
                </p>
              </div>
            </Col>
          </Row>
        </Box>
      </div>
      <p>
        If you want to share your Apocalyptic Shadow clear information with us,
        you simply need to fill the form below and{' '}
        <strong>
          provide us with your UID and make your Hoyo profile public - we will
          automatically parse the MoC clear information from your profile after
          that
        </strong>
        ! The more people join the project, the better and more accurate data we
        can provide!
      </p>
      <Button
        variant="primary"
        href="https://bit.ly/mocstats"
        target="_blank"
        rel="noreferrer"
        className="custom-button"
      >
        AS Stats Form
      </Button>
      <SectionHeader title={`Average score ${currentPhase}`} />
      <Alert variant="primary">
        <p>
          <strong>Disclaimer:</strong> Don't use the data to compare the pull
          value between characters. For more accurate assessment, check our tier
          list and the character reviews.
        </p>
      </Alert>
      <h6>Additional information</h6>
      <ul>
        <li>
          The data has been last updated on <strong>{updateDate},</strong>
        </li>
        <li>
          <strong>{currentTotalUsers} players</strong> shared their{' '}
          {currentPhase} Apocalyptic Shadow clear data with us (
          {currentSelfUsers} of the sample are self-reported players that filled
          our form, {currentRandomUsers} are from random UIDs that we scanned),
        </li>
        <li>
          Compared to Memory of Chaos, we're able to obtain the scores per side,
          so the average shows the actual performance of the character,
        </li>
        <li>
          The data only includes characters information based on the usage in{' '}
          <strong>Apocalyptic Shadow stage 4</strong> as the other stages are
          currently easy to clear by most players.
        </li>
      </ul>
      <div className="filters">
        <FloatingLabel controlId="customTargetRating" label="Config">
          <Form.Select
            aria-label="selected-role"
            value={currentRatingsConfig}
            onChange={(event) => setCurrentRatingsConfig(event.target.value)}
          >
            <option value="default">E0 and all Light Cones</option>
            <option value="free">E0 and only free Light Cones</option>
            <option value="whales">Up to E6 and all Light Cones</option>
          </Form.Select>
        </FloatingLabel>
      </div>
      <h5>
        All characters{' '}
        {currentRatingsConfig === 'default' && <span>[Only E0SX for 5*]</span>}
        {currentRatingsConfig === 'free' && <span>[Only E0S0 for 5*]</span>}
        {currentRatingsConfig === 'whales' && <span>[Up to E6SX for 5*]</span>}
      </h5>
      {currentRatingsConfig === 'default' && (
        <>
          <div className={`char-view simple`}>
            <>
              {characterList
                .sort((a, b) =>
                  a.current_avg_score < b.current_avg_score ? 1 : -1
                )
                .map((character, index) => {
                  return (
                    <div key={index} className="char-box">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.char}
                          mode="icon"
                          enablePopover
                          showIcon
                        />
                      </Card>
                      <div className="column percentage">
                        <p>
                          {character.current_avg_score}
                          {character.char === 'mydei' ||
                          character.char === 'tribbie' ? (
                            <span className="difference">New!</span>
                          ) : (
                            <>
                              <span className="difference">
                                [
                                {character.current_avg_score -
                                  character.prev_avg_score ===
                                0 ? (
                                  <>-</>
                                ) : (
                                  <>
                                    {character.current_avg_score -
                                      character.prev_avg_score >
                                    0 ? (
                                      <span className="green">
                                        <FontAwesomeIcon
                                          icon={faArrowUp}
                                          width="12"
                                          className="arrow-green"
                                        />
                                        {(
                                          character.current_avg_score -
                                          character.prev_avg_score
                                        ).toFixed(0)}
                                      </span>
                                    ) : (
                                      <span className="red">
                                        <FontAwesomeIcon
                                          icon={faArrowDown}
                                          width="12"
                                          className="arrow-red"
                                        />
                                        {(
                                          character.current_avg_score -
                                          character.prev_avg_score
                                        ).toFixed(0)}
                                      </span>
                                    )}
                                  </>
                                )}
                                ]
                              </span>
                            </>
                          )}
                        </p>
                        <p className="usage">{character.current_app_rate}%</p>
                      </div>
                    </div>
                  );
                })}
            </>
          </div>
        </>
      )}
      {currentRatingsConfig === 'whales' && (
        <>
          <div className={`char-view simple`}>
            <>
              {characterList
                .sort((a, b) => (a.round_eidolons < b.round_eidolons ? 1 : -1))
                .map((character, index) => {
                  return (
                    <div key={index} className="char-box">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.char}
                          mode="icon"
                          enablePopover
                          showIcon
                        />
                      </Card>
                      <div className="column percentage with-margin">
                        <p>{character.round_eidolons}</p>
                      </div>
                    </div>
                  );
                })}
            </>
          </div>
        </>
      )}
      {currentRatingsConfig === 'free' && (
        <>
          <div className={`char-view simple`}>
            <>
              {characterList
                .sort((a, b) => (a.round_free < b.round_free ? 1 : -1))
                .map((character, index) => {
                  return (
                    <div key={index} className="char-box">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.char}
                          mode="icon"
                          enablePopover
                          showIcon
                        />
                      </Card>
                      <div className="column percentage">
                        <p>{character.round_free}</p>
                        <p className="usage">{character.app_free}%</p>
                      </div>
                    </div>
                  );
                })}
            </>
          </div>
        </>
      )}
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title={`Character usage ${currentPhase}`} />
      <Alert variant="primary">
        <p>
          <strong>Disclaimer:</strong> Don't use the data to compare the pull
          value between characters. There are a lot that factors in appearance
          rate, such as the turbulence and enemy lineup. The appearance rate
          shouldn't be used to compare characters of different rarities due to
          the difference in their ownership rate.
        </p>
        <p>
          For more accurate assessment, check our tier list and the character
          reviews.
        </p>
      </Alert>
      <h6>Additional information</h6>
      <ul>
        <li>
          The data has been last updated on <strong>{updateDate},</strong>
        </li>
        <li>
          <strong>{currentTotalUsers} players</strong> shared their{' '}
          {currentPhase} Apocalyptic Shadow clear data with us (
          {currentSelfUsers} of the sample are self-reported players that filled
          our form, {currentRandomUsers} are from random UIDs that we scanned),
        </li>
        <li>
          Characters are ranked with appearance rate, which is how often a
          character was used by all players, regardless of whether they own the
          character or not.{' '}
          <strong> Ownership rate currently cannot be obtained.</strong>
        </li>
        <li>
          The data only includes characters information based on the usage in{' '}
          <strong>Apocalyptic Shadow stage 4</strong> as the other stages are
          currently easy to clear by most players.
        </li>
      </ul>
      <div className="configuration">
        <div className="custom-switch">
          <Switch
            checked={characterListCombined}
            onChange={(value) => setCharacterListCombined(value)}
            onColor="#009EEC"
            offColor="#484950"
            className="switch"
          />
          Show combined
        </div>
      </div>
      <>
        {characterListCombined ? (
          <>
            <h5>All characters</h5>
            <div className={`char-view simple`}>
              {characterList
                .sort((a, b) =>
                  b.current_app_rate > a.current_app_rate ? 1 : -1
                )
                .map((character, index) => {
                  return (
                    <div key={index} className="char-box">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.char}
                          mode="icon"
                          enablePopover
                          showIcon
                        />
                      </Card>
                      <div className="column percentage">
                        <p>{character.current_app_rate}%</p>
                        <p className="difference">
                          {character.current_app_rate -
                            character.prev_app_rate ===
                          0 ? (
                            <>-</>
                          ) : (
                            <>
                              {character.current_app_rate -
                                character.prev_app_rate >
                              0 ? (
                                <span className="green">
                                  <FontAwesomeIcon
                                    icon={faArrowUp}
                                    width="18"
                                    className="arrow-green"
                                  />
                                  {(
                                    character.current_app_rate -
                                    character.prev_app_rate
                                  ).toFixed(2)}
                                  %
                                </span>
                              ) : (
                                <span className="red">
                                  <FontAwesomeIcon
                                    icon={faArrowDown}
                                    width="18"
                                    className="arrow-red"
                                  />
                                  {(
                                    character.current_app_rate -
                                    character.prev_app_rate
                                  ).toFixed(2)}
                                  %
                                </span>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <div className="split-version">
            <h5>Limited 5★ characters</h5>
            <div className={`char-view simple`}>
              {characterList
                .filter((char) => char.rarity === 'Limited 5*')
                .sort((a, b) =>
                  b.current_app_rate > a.current_app_rate ? 1 : -1
                )
                .map((character, index) => {
                  return (
                    <div key={index} className="char-box">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.char}
                          mode="icon"
                          enablePopover
                          showIcon
                        />
                      </Card>
                      <div className="column percentage">
                        <p>{character.current_app_rate}%</p>
                        <p className="difference">
                          {character.current_app_rate -
                            character.prev_app_rate ===
                          0 ? (
                            <>-</>
                          ) : (
                            <>
                              {character.current_app_rate -
                                character.prev_app_rate >
                              0 ? (
                                <span className="green">
                                  <FontAwesomeIcon
                                    icon={faArrowUp}
                                    width="18"
                                    className="arrow-green"
                                  />
                                  {(
                                    character.current_app_rate -
                                    character.prev_app_rate
                                  ).toFixed(2)}
                                  %
                                </span>
                              ) : (
                                <span className="red">
                                  <FontAwesomeIcon
                                    icon={faArrowDown}
                                    width="18"
                                    className="arrow-red"
                                  />
                                  {(
                                    character.current_app_rate -
                                    character.prev_app_rate
                                  ).toFixed(2)}
                                  %
                                </span>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <h5>Standard 5★ characters</h5>
            <div className={`char-view simple`}>
              {characterList
                .filter((char) => char.rarity === 'Standard 5*')
                .sort((a, b) =>
                  b.current_app_rate > a.current_app_rate ? 1 : -1
                )
                .map((character, index) => {
                  return (
                    <div key={index} className="char-box">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.char}
                          mode="icon"
                          enablePopover
                          showIcon
                        />
                      </Card>
                      <div className="column percentage">
                        <p>{character.current_app_rate}%</p>
                        <p className="difference">
                          {character.current_app_rate -
                            character.prev_app_rate ===
                          0 ? (
                            <>-</>
                          ) : (
                            <>
                              {character.current_app_rate -
                                character.prev_app_rate >
                              0 ? (
                                <span className="green">
                                  <FontAwesomeIcon
                                    icon={faArrowUp}
                                    width="18"
                                    className="arrow-green"
                                  />
                                  {(
                                    character.current_app_rate -
                                    character.prev_app_rate
                                  ).toFixed(2)}
                                  %
                                </span>
                              ) : (
                                <span className="red">
                                  <FontAwesomeIcon
                                    icon={faArrowDown}
                                    width="18"
                                    className="arrow-red"
                                  />
                                  {(
                                    character.current_app_rate -
                                    character.prev_app_rate
                                  ).toFixed(2)}
                                  %
                                </span>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <h5>Non Warp 5★/4★ characters</h5>
            <div className={`char-view simple`}>
              {characterList
                .filter((char) => char.rarity === '4*')
                .sort((a, b) =>
                  b.current_app_rate > a.current_app_rate ? 1 : -1
                )
                .map((character, index) => {
                  return (
                    <div key={index} className="char-box">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.char}
                          mode="icon"
                          enablePopover
                          showIcon
                        />
                      </Card>
                      <div className="column percentage">
                        <p>{character.current_app_rate}%</p>
                        <p className="difference">
                          {character.current_app_rate -
                            character.prev_app_rate ===
                          0 ? (
                            <>-</>
                          ) : (
                            <>
                              {character.current_app_rate -
                                character.prev_app_rate >
                              0 ? (
                                <span className="green">
                                  <FontAwesomeIcon
                                    icon={faArrowUp}
                                    width="18"
                                    className="arrow-green"
                                  />
                                  {(
                                    character.current_app_rate -
                                    character.prev_app_rate
                                  ).toFixed(2)}
                                  %
                                </span>
                              ) : (
                                <span className="red">
                                  <FontAwesomeIcon
                                    icon={faArrowDown}
                                    width="18"
                                    className="arrow-red"
                                  />
                                  {(
                                    character.current_app_rate -
                                    character.prev_app_rate
                                  ).toFixed(2)}
                                  %
                                </span>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </>
      <SectionHeader title={`Character usage over time`} />
      <p>
        The table below shows the average performance and usage rate in the{' '}
        <strong>last 3 phases of Apocalyptic Shadow</strong> - so what's being
        taken into account for the purpose of our tier list.{' '}
        <strong>
          We don't just look at the last phase data because the buffs and enemy
          layout heavily impact performance of some characters.
        </strong>{' '}
        Keep in mind that sometimes the data can be skewed - for example high
        usage rate usually negatively impacts the character cycles and the
        opposite also happens when a character isn't that popular, but performs
        above expectations thanks to dedicated fans. We don't take the data at
        face value, but further correlate it with our testing to get a better
        picture of which characters make clearing MoC easier.
      </p>
      <p>
        Characters with <strong>*</strong> behind the data only have 1 phase
        worth of data, so they are pretty skewed.
      </p>
      <Row xs={1} xl={2} xxl={4} className="histo-info apo">
        <Col>
          <h5 className="dps">
            <FontAwesomeIcon icon={faHandFist} width="18" /> Damage dealer
          </h5>
          <div className="histo-table">
            <div className="histo-row header">
              <div className="header first">&nbsp;Char.</div>
              <div className="header">Av. Score</div>
              <div className="header">Av. Usage</div>
            </div>
            {HSRApoHisto.filter((char) => char.role === 'dps')
              .sort((a, b) => (a.score < b.score ? 1 : -1))
              .map((character, index) => {
                return (
                  <div className="histo-row" key={index}>
                    <div className="character">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.slug}
                          mode="icon"
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div
                      className={`cycle  ${
                        character.score > 3500 && 'score-6'
                      } ${
                        character.score >= 3400 &&
                        character.score < 3500 &&
                        'score-7'
                      } ${
                        character.score >= 3300 &&
                        character.score < 3400 &&
                        'score-8'
                      } ${
                        character.score >= 3200 &&
                        character.score < 3300 &&
                        'score-9'
                      }  ${
                        character.score >= 3100 &&
                        character.score < 3200 &&
                        'score-10'
                      }  ${character.score < 3100 && 'score-11'}`}
                    >
                      <p>
                        {character.score < 3100 ? (
                          '< 3100'
                        ) : (
                          <>
                            {character.score}
                            {character.new && '*'}
                          </>
                        )}
                      </p>
                    </div>
                    <div
                      className={`usage ${character.usage > 75 && 'score-7'} ${
                        character.usage > 50 &&
                        character.usage <= 75 &&
                        'score-8'
                      } ${
                        character.usage >= 25 &&
                        character.usage < 50 &&
                        'score-9'
                      } ${
                        character.usage >= 5 &&
                        character.usage < 25 &&
                        'score-10'
                      } ${character.usage <= 5 && 'score-11'}`}
                    >
                      <p>
                        {character.usage}%{character.new && '*'}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </Col>
        <Col>
          <h5 className="specialist">
            <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
          </h5>
          <div className="histo-table">
            <div className="histo-row header">
              <div className="header first">&nbsp;Char.</div>
              <div className="header">Av. Score</div>
              <div className="header">Av. Usage</div>
            </div>
            {HSRApoHisto.filter((char) => char.role === 'specialist')
              .sort((a, b) => (a.score < b.score ? 1 : -1))
              .map((character, index) => {
                return (
                  <div className="histo-row" key={index}>
                    <div className="character">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.slug}
                          mode="icon"
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div
                      className={`cycle  ${
                        character.score > 3500 && 'score-6'
                      } ${
                        character.score >= 3400 &&
                        character.score < 3500 &&
                        'score-7'
                      } ${
                        character.score >= 3300 &&
                        character.score < 3400 &&
                        'score-8'
                      } ${
                        character.score >= 3200 &&
                        character.score < 3300 &&
                        'score-9'
                      }  ${
                        character.score >= 3100 &&
                        character.score < 3200 &&
                        'score-10'
                      }  ${character.score < 3100 && 'score-11'}`}
                    >
                      <p>
                        {character.score < 3100 ? (
                          '< 3100'
                        ) : (
                          <>
                            {character.score}
                            {character.new && '*'}
                          </>
                        )}
                      </p>
                    </div>
                    <div
                      className={`usage ${character.usage > 75 && 'score-7'} ${
                        character.usage > 50 &&
                        character.usage <= 75 &&
                        'score-8'
                      } ${
                        character.usage >= 25 &&
                        character.usage < 50 &&
                        'score-9'
                      } ${
                        character.usage >= 5 &&
                        character.usage < 25 &&
                        'score-10'
                      } ${character.usage <= 5 && 'score-11'}`}
                    >
                      <p>
                        {character.usage}%{character.new && '*'}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </Col>
        <Col>
          <h5 className="amplifier">
            <FontAwesomeIcon icon={faFlask} width="18" /> Amplifier
          </h5>
          <div className="histo-table">
            <div className="histo-row header">
              <div className="header first">&nbsp;Char.</div>
              <div className="header">Av. Score</div>
              <div className="header">Av. Usage</div>
            </div>
            {HSRApoHisto.filter((char) => char.role === 'amplifier')
              .sort((a, b) => (a.score < b.score ? 1 : -1))
              .map((character, index) => {
                return (
                  <div className="histo-row" key={index}>
                    <div className="character">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.slug}
                          mode="icon"
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div
                      className={`cycle  ${
                        character.score > 3500 && 'score-6'
                      } ${
                        character.score >= 3400 &&
                        character.score < 3500 &&
                        'score-7'
                      } ${
                        character.score >= 3300 &&
                        character.score < 3400 &&
                        'score-8'
                      } ${
                        character.score >= 3200 &&
                        character.score < 3300 &&
                        'score-9'
                      }  ${
                        character.score >= 3100 &&
                        character.score < 3200 &&
                        'score-10'
                      }  ${character.score < 3100 && 'score-11'}`}
                    >
                      <p>
                        {character.score < 3100 ? (
                          '< 3100'
                        ) : (
                          <>
                            {character.score}
                            {character.new && '*'}
                          </>
                        )}
                      </p>
                    </div>
                    <div
                      className={`usage ${character.usage > 75 && 'score-7'} ${
                        character.usage > 50 &&
                        character.usage <= 75 &&
                        'score-8'
                      } ${
                        character.usage >= 25 &&
                        character.usage < 50 &&
                        'score-9'
                      } ${
                        character.usage >= 5 &&
                        character.usage < 25 &&
                        'score-10'
                      } ${character.usage <= 5 && 'score-11'}`}
                    >
                      <p>
                        {character.usage}%{character.new && '*'}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </Col>
        <Col>
          <h5 className="sustain">
            <FontAwesomeIcon icon={faBriefcaseMedical} width="18" /> Sustain
          </h5>
          <div className="histo-table">
            <div className="histo-row header">
              <div className="header first">&nbsp;Char.</div>
              <div className="header">Av. Score</div>
              <div className="header">Av. Usage</div>
            </div>
            {HSRApoHisto.filter((char) => char.role === 'sustain')
              .sort((a, b) => (a.score < b.score ? 1 : -1))
              .map((character, index) => {
                return (
                  <div className="histo-row" key={index}>
                    <div className="character">
                      <Card className="avatar-card">
                        <HSRCharacter
                          slug={character.slug}
                          mode="icon"
                          enablePopover
                        />
                      </Card>
                    </div>
                    <div
                      className={`cycle  ${
                        character.score > 3500 && 'score-6'
                      } ${
                        character.score >= 3400 &&
                        character.score < 3500 &&
                        'score-7'
                      } ${
                        character.score >= 3300 &&
                        character.score < 3400 &&
                        'score-8'
                      } ${
                        character.score >= 3200 &&
                        character.score < 3300 &&
                        'score-9'
                      }  ${
                        character.score >= 3100 &&
                        character.score < 3200 &&
                        'score-10'
                      }  ${character.score < 3100 && 'score-11'}`}
                    >
                      <p>
                        {character.score < 3100 ? (
                          '< 3100'
                        ) : (
                          <>
                            {character.score}
                            {character.new && '*'}
                          </>
                        )}
                      </p>
                    </div>
                    <div
                      className={`usage ${character.usage > 75 && 'score-7'} ${
                        character.usage > 50 &&
                        character.usage <= 75 &&
                        'score-8'
                      } ${
                        character.usage >= 25 &&
                        character.usage < 50 &&
                        'score-9'
                      } ${
                        character.usage >= 5 &&
                        character.usage < 25 &&
                        'score-10'
                      } ${character.usage <= 5 && 'score-11'}`}
                    >
                      <p>
                        {character.usage}%{character.new && '*'}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </Col>
      </Row>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title={`Team usage ${currentPhase}`} />
      <Alert variant="primary">
        <p>
          <strong>Disclaimer:</strong> Don't use the data to compare the pull
          value between characters. There are a lot that factors in appearance
          rate, such as the memory turbulence and enemy lineup.
        </p>
        <p>
          For more accurate assessment, check our tier list and the character
          reviews.
        </p>
      </Alert>
      <h6>Additional information</h6>
      <ul>
        <li>
          The data has been last updated on <strong>{updateDate},</strong>
        </li>
        <li>
          <strong>{currentTotalUsers} players</strong> shared their{' '}
          {currentPhase} Apocalyptic Shadow clear data with us (
          {currentSelfUsers} of the sample are self-reported players that filled
          our form, {currentRandomUsers} are from random UIDs that we scanned),
        </li>
        <li>
          The data only includes characters information based on the usage in{' '}
          <strong>Apocalyptic Shadow stage 4.</strong>
        </li>
        <li>
          If a team used a limited 5* E1+ character, it was excluded from the
          average score calculation,
        </li>
        <li>
          <strong>The % number below the Rank are calculated per stage</strong>.
        </li>
      </ul>
      <div className="filters">
        <FloatingLabel controlId="customTarget" label="Stage">
          <Form.Select
            aria-label="selected-role"
            value={currentTeamStageConfig}
            onChange={(event) => setCurrentTeamStageConfig(event.target.value)}
          >
            <option value="all">{currentPhase} - Stage 4 (combined)</option>
            <option value="stage_4">{currentPhase} - Stage 4 (sides)</option>
            {/* <option value="previous_all">
              {prevPhase} - Stage 4 (combined)
            </option>
            <option value="previous_stage_4">
              {prevPhase} - Stage 4 (sides)
            </option> */}
          </Form.Select>
        </FloatingLabel>
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="character-filter"
          aria-expanded={open}
        >
          <FontAwesomeIcon icon={faFilter} width="16" />
          {open ? 'Hide' : 'Show'} character filters
        </Button>
      </div>
      <Collapse in={open}>
        <div id="character-filter">
          <div className="character-filters">
            <h6>Character filter</h6>
            <p>
              Select the characters below that you own to show teams containing
              them (by default, all are selected). You can also enable the{' '}
              <strong>Strict Mode</strong> to search for a specific team
              composition.
            </p>
            <div className="filters-container">
              {createFilterCharacterIcons(null)}
            </div>
            <div className="options">
              <Button
                onClick={resetStuff}
                variant="primary"
                className="reset-button"
                disabled={
                  currentFilterMode === FilterMode.includeAll ? true : false
                }
              >
                Select all
              </Button>
              <Button
                onClick={unsetStuff}
                variant="primary"
                className="reset-button"
              >
                Deselect all
              </Button>
              <div className="custom-switch">
                <Switch
                  checked={currentFilterMode === FilterMode.includeAll}
                  onChange={toggleFilterMode}
                  onColor="#009EEC"
                  offColor="#484950"
                  className="switch"
                />
                <span className="text">Strict mode</span>
              </div>
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="auto-start"
                overlay={popoverStrict}
              >
                <span className="trigger-help">
                  <FontAwesomeIcon
                    icon={faCircleQuestion}
                    className="arrow"
                    width="18"
                  />
                </span>
              </OverlayTrigger>
              <div className="custom-switch">
                <Switch
                  checked={enableCycle}
                  onChange={() => setEnableCycle(!enableCycle)}
                  onColor="#009EEC"
                  offColor="#484950"
                  className="switch"
                />
                <span className="text">Sort by score</span>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
      <Row xs={1} xxl={2} className="teams-details">
        <Col>
          <div className="box">
            <h5>
              {currentTeamStageConfig === 'all' ? 'Both stages' : 'Sub-stage 1'}
            </h5>
            <p className="team-number">
              There's <strong>{currentTeamStageFiltered.length}</strong> team(s)
              in the database matching the criteria.
            </p>
            {items}
            {items.length === 0 && (
              <div className="no-results">
                <StaticImage
                  src="../../images/starrail/no_results.png"
                  alt="No results"
                />
                <p>No teams found. Try selecting more characters.</p>
              </div>
            )}
          </div>
        </Col>
        <Col>
          <div className="box">
            <h5>Sub-stage 2</h5>
            {currentTeamStageConfig === 'all' ||
            currentTeamStageConfig === 'previous_all' ? (
              <>
                <p>
                  The all stages data aren't split into stages, but instead show
                  overall team usage across all of them. Pick a specific stage
                  to see the teams used in each sub-stage.
                </p>
              </>
            ) : (
              <>
                <p className="team-number">
                  There's <strong>{currentTeamStage2Filtered.length}</strong>{' '}
                  team(s) in the database matching the criteria.
                </p>
                {items2}
                {items2.length === 0 && (
                  <div className="no-results">
                    <StaticImage
                      src="../../images/starrail/no_results.png"
                      alt="No results"
                    />
                    <p>No teams found. Try selecting more characters.</p>
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
      <div className="show-more-section">
        {currentShow === 10 ? (
          <>
            <button className="btn btn-primary" onClick={handleShowMore}>
              Show more teams
            </button>
          </>
        ) : (
          <>
            <button className="btn btn-primary" onClick={handleShowLess}>
              Show less teams
            </button>
          </>
        )}
      </div>
      <br /> <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default HSRApoAnalyticsPage;

export const Head: React.FC = () => (
  <Seo
    title="Apocalyptic Shadow Analytics | Honkai: Star Rail | Prydwen Institute"
    description="Information and analytics about the current and past Apocalyptic Shadow phases! Learn about the teams used by others to clear Apocalyptic Shadow!"
  />
);
